html {
  overscroll-behavior-y: none;
  height: 300px;
  overflow: hidden;
  touch-action: none;
  overscroll-behavior: contain;
  top: -500px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow: hidden;
  touch-action: none;
  position: fixed;
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
  height: 200px;
}


/* Отключение эффекта резиновости (bounce) в области тач-событий */
/*body, button, input, select, textarea {*/
/*  -webkit-overflow-scrolling: touch;*/
/*  overflow-y: auto;*/
/*}*/

/* Отключение выделения текста на элементах */
img {
  -webkit-user-select: none;
  user-select: none;
}

/* Отключение контуров выделения на элементах */
/*:focus {*/
/*  outline: none;*/
/*}*/

/*!* Отключение анимации на клик *!*/
/** {*/
/*  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);*/
/*}*/

#root {
  overflow: hidden;
  /*touch-action: none;*/
  width: 100%;
  position: fixed;
  height: 100%;
  /*overscroll-behavior: contain;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.stats {
  top: auto !important;
  bottom: 0px;
}